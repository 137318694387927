import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ChakraIcons,
  Flex,
  Heading,
  Picto,
  Select,
  Slider,
  Spinner,
  Text
} from '@stars-ecom/shared-atoms-ui'
import {
  ApiContext,
  DataLayerUtils,
  //OfferUtils,
  ProductThumbnail,
  WebsiteContext
} from '@stars-ecom/shared-organisms-ui'
import cloneDeep from 'lodash/cloneDeep'
import first from 'lodash/first'
import last from 'lodash/last'
import size from 'lodash/size'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

const DISPLAY_LIMIT = 100

const SearchPage = (props) => {
  const { location } = props
  const [products, setProducts] = useState([])
  const [terms, setTerms] = useState()
  const [itemsCount, setItemsCount] = useState(0)
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [sort, setSort] = useState({})
  const [filter] = useState({})
  const [loading, setLoading] = useState(false)
  const [filteredProducts, setFilteredProducts] = useState([])
  const [priceRange, setPriceRange] = useState([])
  const [limit, setLimit] = useState(DISPLAY_LIMIT)
  const websiteContext = useContext(WebsiteContext)
  const apiContext = useContext(ApiContext)
  const [minValue, setMin] = useState(0)
  const [maxValue, setMax] = useState(1000)

  const getData = async () => {
    if (terms?.trim().length > 0) {
      setLoading(true)
      const result = await apiContext?.SearchApi?.search(terms, sort, filter)
      setProducts(result.items)
      setFilteredProducts(cloneDeep(result.items))
      DataLayerUtils.updateDataLayer({ nbResultat: result?.items?.length })
      setItemsCount(result.totalItems)
      const range = getPriceRange(result.items)
      setPriceRange(range)
      setMin(first(range))
      setMax(last(range))
      setLoading(false)
    }
  }

  const orderProducts = (sort) => {
    if (sort?.type == 'name' && sort?.direction == 'asc') {
      setFilteredProducts(
        filteredProducts.sort((a, b) => {
          return a?.name?.localeCompare(b?.name)
        })
      )
    } else if (sort?.type == 'name' && sort?.direction == 'desc') {
      setFilteredProducts(
        filteredProducts.sort((a, b) => {
          return b?.name?.localeCompare(a?.name)
        })
      )
    } else if (sort?.type == 'price' && sort?.direction == 'asc') {
      setFilteredProducts(
        filteredProducts.sort((a, b) => {
          return a?.variants[0]?.priceWithTax - b?.variants[0]?.priceWithTax
        })
      )
    } else if (sort?.type == 'price' && sort?.direction == 'desc') {
      setFilteredProducts(
        filteredProducts.sort((a, b) => {
          return b?.variants[0]?.priceWithTax - a?.variants[0]?.priceWithTax
        })
      )
    } else if (sort?.type == 'tvShow') {
      setFilteredProducts(
        cloneDeep(products).sort((a, b) => {
          return (
            new Date(
              b.facetValues
                .filter((fv) => fv?.facet?.code == 'tvshow')
                ?.reduce(
                  (a, b) => (new Date(a?.facet?.code) > new Date(b?.facet?.code) ? [a] : [b]),
                  [{ code: new Date('2000-01-01') }]
                )[0]?.code
            ) -
            new Date(
              a.facetValues
                .filter((fv) => fv?.facet?.code == 'tvshow')
                ?.reduce(
                  (a, b) => (new Date(a?.facet?.code) > new Date(b?.facet?.code) ? [a] : [b]),
                  [{ code: new Date('2000-01-01') }]
                )[0]?.code
            )
          )
        })
      )
    } else {
      setFilteredProducts(cloneDeep(products))
    }
  }

  const doOrdering = async (event) => {
    let order = event.target.value
    let orderInfos = order.split('-')
    let sort = { type: orderInfos[0], direction: orderInfos[1] }
    setSort(sort)
    orderProducts(sort)
  }

  const showFilters = () => {
    setFiltersVisible(!filtersVisible)
  }

  useEffect(() => {
    getData()
  }, [terms])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.has('q')) {
      setTerms(params.get('q'))
    }
  }, [location.search])

  const getPriceRange = (_products) => {
    const minValue =
      _products.reduce((a, c) => Math.min(a, c.variants[0].priceWithTax), Infinity) / 100
    const maxValue = _products.reduce((a, c) => Math.max(a, c.variants[0].priceWithTax), 0) / 100
    const diff = maxValue - minValue

    const result = [
      Math.round(minValue),
      Math.round(minValue + diff / 5),
      Math.round(minValue + (2 * diff) / 5),
      Math.round(minValue + (3 * diff) / 5),
      Math.round(minValue + (4 * diff) / 5),
      Math.round(maxValue)
    ]

    return result
  }

  const handleChangePriceRange = (values) => {
    setLimit(DISPLAY_LIMIT)
    const result = products.filter(
      (product) =>
        Math.round(product.variants[0].priceWithTax / 100) >= values[0] &&
        Math.round(product.variants[0].priceWithTax / 100) <= values[1]
    )

    setFilteredProducts(result)
  }

  const seeMore = () => {
    setLimit(limit + DISPLAY_LIMIT)
  }

  const breadCrumbData = [
    { label: 'Accueil', link: '/' },
    { label: `Résultats de recherche pour : '${terms}'`, link: '#' }
  ]

  useEffect(() => {
    DataLayerUtils.addProductImpression({
      command: 'productImpression',
      productList: filteredProducts,
      listName: 'Page de recherche'
    })
  }, [filteredProducts])

  return (
    <Flex direction="column" align="center" w="100%">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Flex maxWidth="1000px" className="breadcrumb-wrapper" w="100%" margin="auto">
        <Breadcrumb spacing="4px" separator="/" fontFamily="PT Sans" className="breadcrumb">
          {breadCrumbData.map((d, i) => (
            <BreadcrumbItem key={`breadcrumb_${i}`} isCurrentPage={i === size(breadCrumbData) - 1}>
              <BreadcrumbLink
                href={i === size(breadCrumbData) - 1 ? undefined : d?.link}
                isCurrentPage={i === size(breadCrumbData) - 1}>
                {d?.label}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </Flex>
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="100%"
        style={{ backgroundColor: '#f6f3f1' }}>
        {loading ? (
          <Flex width="100%" height="300px" justify="center" align="center">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <>
            <Heading
              fontFamily="PT Sans Narrow"
              as="h1"
              color="#6c6c6c"
              lineHeight="1"
              display={{ base: 'flex', md: 'block' }}
              flexDirection={{ base: 'column', md: 'row' }}
              textAlign={{ base: 'center', md: 'left' }}
              width={{ base: '100%', md: '100%', lg: '1000px' }}
              fontSize={{ base: '6vw', md: '40px' }}
              pl={{ base: '10px', md: '15px' }}
              pt="40px">
              {terms?.trim().length > 0 ? (
                <>
                  <Text as="span" color="#000000">
                    Résultat :
                  </Text>{' '}
                  <Text as="span">
                    {itemsCount > 0
                      ? `${itemsCount} produit${itemsCount > 1 ? 's' : ''}`
                      : 'Aucun résultat'}{' '}
                    pour votre recherche {`"${terms}"`}
                  </Text>
                </>
              ) : (
                <Text as="span">Veuillez entrer des termes de recherche</Text>
              )}
            </Heading>
            {itemsCount > 1 ? (
              <Flex
                justify="space-between"
                width="100%"
                maxWidth={{ base: '100%', md: '1000px' }}
                mt="20px"
                p={{ base: '15px', md: '15px' }}>
                <Flex
                  className="search-select-item"
                  onClick={showFilters}
                  w={{ base: '34%', md: '135px' }}
                  h="43px"
                  fontSize={{ base: '13px', md: '14px' }}
                  p="10px 20px"
                  fontFamily="PT Sans, Arial, Sans-serif"
                  alignItems="center"
                  justifyContent="space-between"
                  backgroundColor="white"
                  border={
                    filtersVisible ? `1px solid ${websiteContext.mainColor}` : '1px solid #c1c1c1'
                  }
                  _hover={{ cursor: 'pointer' }}>
                  <Text color={filtersVisible && websiteContext.mainColor}>Filtrer par</Text>
                  <ChakraIcons.TriangleDownIcon
                    color={filtersVisible && websiteContext.mainColor}
                    w={2}
                    h={2}
                  />
                </Flex>
                <Select
                  onChange={doOrdering}
                  w={{ base: '64%', md: '215px' }}
                  placeholder="Trier par"
                  h="43px"
                  fontSize={{ base: '13px', md: '14px' }}
                  backgroundColor="white"
                  border="1px solid #c1c1c1"
                  borderColor="#c1c1c1"
                  borderRadius="0"
                  _focus={{ borderColor: websiteContext?.mainColor }}
                  icon={<ChakraIcons.TriangleDownIcon />}
                  iconSize="8">
                  <option value="pertinence">Pertinence</option>
                  <option value="tvShow-desc">Vu récemment à l&apos;émission</option>
                  <option value="price-desc">Du + cher au - cher</option>
                  <option value="price-asc">Du - cher au + cher</option>
                  <option value="name-asc">De A à Z</option>
                  <option value="name-desc">De Z à A</option>
                </Select>
              </Flex>
            ) : itemsCount > 0 ? (
              <Box></Box>
            ) : (
              <Box
                w={{ base: 'auto', md: '1000px' }}
                m="auto"
                pt="30px"
                fontSize="14px"
                fontFamily={websiteContext.fontFamily}>
                Aucun produit ne correspond à la sélection.
              </Box>
            )}

            <Flex
              className="search-select"
              justifyContent="space-between"
              w={{ base: '100%', md: '1000px' }}>
              {filtersVisible && itemsCount > 0 && (
                <Flex w="190px">
                  <Box
                    position="relative"
                    border="15px solid transparent"
                    top="0"
                    left={{ base: '50px', md: '44px' }}
                    style={{
                      content: `""`,
                      borderBottomColor: websiteContext?.mainColor
                    }}></Box>
                  <Box
                    position="relative"
                    border="15px solid transparent"
                    top="3px"
                    left={{ base: '20px', md: '14px' }}
                    style={{
                      content: `""`,
                      borderBottomColor: '#fff'
                    }}></Box>
                </Flex>
              )}
            </Flex>
            {filtersVisible && itemsCount > 0 && (
              <Flex
                justifyContent="space-around"
                p="20px 0"
                w="100%"
                bg="#fff"
                border="1px"
                borderColor="#cfd5e3"
                borderTop="2px"
                borderTopColor={websiteContext?.mainColor}>
                <Flex
                  className="slider-container"
                  direction="column"
                  w={{ base: '100%', md: '40%' }}
                  p={{ md: '20px' }}>
                  <Text
                    fontFamily="PT Sans, Arial, sans-serif"
                    fontSize="14px"
                    fontWeight="700"
                    textTransform="uppercase"
                    m="20px 20px 26px"
                    ml={{ md: '36px' }}>
                    prix
                  </Text>
                  <Slider
                    range={priceRange}
                    handleChange={handleChangePriceRange}
                    minValue={minValue}
                    setMin={setMin}
                    maxValue={maxValue}
                    setMax={setMax}
                    color={websiteContext?.mainColor}
                  />
                </Flex>
              </Flex>
            )}
            <Flex
              width={{ base: '100%', md: '100%', lg: '1000px' }}
              display="grid"
              gridTemplateColumns={{
                base: '49% 49%',
                md: '32.75% 32.75% 32.75%',
                lg: '24.5% 24.5% 24.5% 24.5%'
              }}
              rowGap="7px"
              columnGap={{ base: '2%', md: '0.75%', lg: '0.66%' }}
              justifyItems={{ base: 'center', md: 'center', lg: 'initial' }}
              mt="15px"
              mb={itemsCount == 0 ? undefined : '15px'}
              className="product-container"
              p={{ base: '15px', md: '15px' }}>
              {filteredProducts?.slice(0, limit).map((p, i) => {
                return (
                  <Flex key={`product_${i}`} width="100%">
                    <ProductThumbnail
                      product={p}
                      position={i}
                      isList={true}
                      index={i}
                      listName={'Page de recherche'}
                    />
                  </Flex>
                )
              })}
              {limit < filteredProducts.length && (
                <Flex
                  w="340px"
                  height="48px"
                  bg="#FFFFFF"
                  border="1px solid #c1c1c1"
                  p="0 20px"
                  justify="center"
                  align="center"
                  onClick={seeMore}>
                  <Picto icon="plusCercle" width="16px" />
                  <Text
                    fontFamily="PT Sans"
                    fontWeight="700"
                    fontSize="14px"
                    ml="10px"
                    _hover={{ color: websiteContext.mainColor, cursor: 'pointer' }}>
                    VOIR LES {Math.min(DISPLAY_LIMIT, filteredProducts.length - limit)} PRODUITS
                    SUIVANTS (SUR {filteredProducts.length})
                  </Text>
                </Flex>
              )}
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  )
}

SearchPage.pageType = 'search'

export default SearchPage
